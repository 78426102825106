<template>
  <BModal
    id="manualPayment"
    ref="manualPayment"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <ValidationObserver ref="formRules"
      v-slot="{ invalid }">
      <div class="my-1">
        <img
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
          alt="Komerce"
          class="m-auto w-[90px]"
        >
        <div class="font-semibold text-center text-12 text-black my-1">
          Kamu Yakin ingin Bayar Manual?
        </div>
        <p class="text-black text-center text-8">
          "Pastikan sudah mengecek bukti pembayaran!"
        </p>
        <BCol md="12" class="d-flex flex-column gap-10">
          <BFormGroup
            label-class="font-medium text-7 text-left text-black"
          >
            <template #label>
              Keterangan <span class="text-danger">*</span>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Bukti Transfer"
              rules="required"
            >
              <BFormTextarea v-model="notes"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
          <BFormGroup
            label-class="font-medium text-7 text-left text-black"
          >
            <template #label>
              Bukti Transfer <span class="text-danger">*</span>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Keterangan"
              rules="required"
            >
              <div class="relative gap-10">
                <input
                  type="file"
                  name="proof"
                  id="payment-proof"
                  accept=".jpg,.jpeg,.png"
                  @change="uploadImage($event)"
                >
                <b-img class="rounded image-proof" :src="imageFile ? fileUrl(imageFile) : ''" v-if="imageFile"/>
                <div class="d-flex flex-column input-container text-center py-[20px] rounded" v-else>
                  <span class="k-folder-open color-orange"></span>
                  <span class="text-8 color-orange">Tarik untuk memilih file baru</span>
                  <span class="text-7 color-orange">Pilih bukti transfer kamu</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <div class="flex justify-center px-[14px]">
          <BButton
            size="md"
            variant="outline-primary"
            class="mr-1 w-100"
            @click="$bvModal.hide('manualPayment')"
          >
            Kembali
          </BButton>
          <BButton
            size="md"
            variant="primary"
            class=" w-100"
            block
            :disabled="invalid || loadingSubmit || !imageFile"
            @click="sendProof()"
          >
            Bayar
          </BButton>
        </div>
      </div>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { alertError, alertSuccess } from '@toast'
import { komtimAxiosIns } from '@/libs/axios'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      notes: '',
      imageFile: null,
      loadingSubmit: false,
      alertError,
      alertSuccess,
    }
  },
  methods: {
    uploadImage(e) {
      const img = e.target.value
      const file = e.target.files[0]
      const fileExtension = img.substr((img.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
        this.imageFile = null
      } else {
        this.imageFile = file
      }
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    async sendProof() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('notes', this.notes)
      formData.append('payment_proof', this.imageFile)

      const url = `v1/invoices/${this.$route.params.id}/pay`
      await komtimAxiosIns
        .put(url, formData)
        .then(() => {
          const text = 'Pembayaran manual berhasil'
          this.alertSuccess(text)

          this.$bvModal.hide('manualPayment')
          this.$router.back()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
  },
}
</script>

<style scoped>
#payment-proof {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.input-container {
  border-style: dashed;
  border-width: 1px;
  border-color: #F95031;
}
.color-orange {
  color: #F95031;
}
.image-proof {
  object-fit: cover;
  max-height: 200px;
  width: 100%;
}
</style>
